// // Custom Theming for Angular Material
// // For more information: https://material.angular.io/guide/theming
// @use '@angular/material' as mat;

// @include mat.core();
// // Plus imports for other components in your app.

// // Include the common styles for Angular Material. We include this here so that you only
// // have to load a single css file for Angular Material in your app.
// // Be sure that you only ever include this mixin once!
// @include mat.core();

// // Define the palettes for your theme using the Material Design palettes available in palette.scss
// // (imported above). For each palette, you can optionally specify a default, lighter, and darker
// // hue. Available color palettes: https://material.io/design/color/
// $unawa-dms-UI-primary: mat.define-palette(mat.$indigo-palette);
// $unawa-dms-UI-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// // The warn palette is optional (defaults to red).
// $unawa-dms-UI-warn: mat.define-palette(mat.$red-palette);

// // Create the theme object. A theme consists of configurations for individual
// // theming systems such as "color" or "typography".
// $unawa-dms-UI-theme: mat.define-light-theme(
//   (
//     color: (
//       primary: $unawa-dms-UI-primary,
//       accent: $unawa-dms-UI-accent,
//       warn: $unawa-dms-UI-warn,
//     ),
//   )
// );
@use '@angular/material' as mat;
@include mat.core();
$theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: mat.$violet-palette,
  ),
  typography: (
    brand-family: 'DM Sans',
    bold-weight: 900
  ),
  density: (
    scale: -1
  )
));
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include mat.all-component-themes($unawa-dms-UI-theme);
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap");

// $custom-typography: mat-typography-config(
//   $font-family: "DM Sans",
// );

// @include angular-material-typography($custom-typography);

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "DM Sans", sans-serif;
}

* {
  font-family: "DM Sans", sans-serif;
}
